<template>
  <div class="sect_info_3">
    <div class="tit" v-if="!isShop">장바구니에 담긴 상품</div>
    <div class="tit" v-else>선택한 상품</div>
    <template v-for="(option, index) in orderData.cart_options">
      <div class="cont" :key="`main_${index}`" :id="`main_${index}`">
        <div class="cont_1">
          <p>{{ orderData.goods.goods_name }}</p>
          <router-link
            to=""
            @click.native="removeItem($event)"
            v-if="orderData.cart_options.length > 1 && !isShop"
            >X</router-link
          >
          <router-link
            to=""
            @click.native="removeItem($event)"
            v-else-if="isShop && option.title1"
            >X</router-link
          >
          <p v-if="option.title1">{{ option.title1 }} : {{ option.option1 }}</p>
          <p v-if="option.title2">{{ option.title2 }} : {{ option.option2 }}</p>
          <p v-if="option.title3">{{ option.title3 }} : {{ option.option3 }}</p>
          <p v-if="option.title4">{{ option.title4 }} : {{ option.option4 }}</p>
          <p v-if="option.title5">{{ option.title5 }} : {{ option.option5 }}</p>
        </div>
        <div class="cont_2">
          <router-link to="" @click.native="eaMinus($event)">-</router-link
          ><input
            type="text"
            name=""
            :value="option.ea"
            readonly
            class="optionPrice ea_change"
            :data-consumer_price="option.consumer_price"
            :data-price="parseInt(option.price)"
            :data-idx="parseInt(index)"
            :data-title="`${option.title1}|${option.title2}|${option.title3}|${option.title4}|${option.title5}`"
            :data-option="`${option.option1}|${option.option2}|${option.option3}|${option.option4}|${option.option5}`"
          /><router-link to="" @click.native="eaPlus($event)">+</router-link>
          <span></span>
        </div>
      </div>
      <div
        class="cont"
        v-for="(sub, idx) in option.cart_suboptions"
        :key="`sub_${index}${idx}`"
        :id="`sub_${index}${idx}`"
      >
        <div class="cont_1">
          <p>{{ sub.suboption_title }} : {{ sub.suboption }}</p>
          <router-link to="" @click.native="removeItem($event)">X</router-link>
        </div>
        <div class="cont_2">
          <router-link to="" @click.native="eaMinus($event)">-</router-link
          ><input
            type="text"
            name=""
            :value="sub.ea"
            readonly
            class="suboptionPrice ea_change"
            :data-consumer_price="sub.consumer_price"
            :data-price="parseInt(sub.price)"
            :data-parent_idx="parseInt(index)"
            :data-idx="parseInt(idx)"
            :data-sub_title="sub.suboption_title"
            :data-sub_option="sub.suboption"
          /><router-link to="" @click.native="eaPlus($event)">+</router-link
          ><span></span>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  data() {
    return {
      gl_option_select_ver: "0.2",
      currency: "KRW",
      currency_symbol: "원",
      currency_symbol_position: "after",
      selectboxOnOpen: "",
      selectboxOnClose: "",
      use_option: false,
      add_action_to_button: false,
      option_change_type: false,
      mobile_mode: false,
      debug_mode: false,
      goods_seq: 0,
      goods_price: 0,
      apply_option_seq: 0,
      option_current_no: 0,
      option_divide_title_count: 0,
      member_seq: 0,
      member_group: 0,
      multi_discount_ea: 0,
      multi_discount: 0,
      cutting_sale_price: 0,
      string_price_use: "",
      multi_discount_policylist: "",
      multi_discount_unit: "",
      cutting_sale_action: "",
      inputoption_data: "",
      total_compare_currency_lay: "total_compare_currency",
      string_button_use: "",
      org_price: 0,
      event_sale_unit: 0,
      event_target_sale: "",
      mobile_sale_unit: 0,
      member_sale_unit: 0,
      member_sale_type: 0,
      min_apply_option_seq: 0,
    };
  },
  updated() {
    this.calculate_goods_price();
  },
  props: {
    orderData: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    isShop: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    setData() {
      const data = {
        option_divide_title_count: this.orderData.goods.option_divide_title
          ? this.orderData.goods.option_divide_title.length
          : this.option_divide_title_count,

        goods_seq: this.orderData.goods.goods_seq,
        goods_price:
          this.orderData.goods.price > this.orderData.goods.sale_price
            ? this.orderData.goods.sale_price
            : this.orderData.goods.price
            ? this.orderData.goods.price
            : 0,
        member_seq: this.orderData.sessionMember.member_seq,
        group_seq: this.orderData.sessionMember.group_seq,
        string_price_use: this.orderData.goods.string_price_use
          ? this.orderData.goods.string_price_use
          : "skin",
        min_purchase_ea: this.orderData.goods.min_purchase_ea,
        max_purchase_ea: this.orderData.goods.max_purchase_ea,
        option_view_type: this.orderData.goods.option_view_type,
        suboption_layout_group: this.orderData.goods.suboption_layout_group,
        suboption_layout_position:
          this.orderData.goods.suboption_layout_position,
        inputoption_layout_group: this.orderData.goods.inputoption_layout_group,
        inputoption_layout_position:
          this.orderData.goods.inputoption_layout_position,
        currency: this.currency,
        currency_symbol: this.currency_symbol,
        currency_symbol_position: this.currency_symbol_position,
        string_button_use: this.orderData.goods.string_button_use,
        event_sale_unit: this.event_sale_unit,
        event_target_sale: this.event_target_sale,
        member_sale_unit: this.member_sale_unit,
        member_sale_type: this.member_sale_type,
        cutting_sale_price: this.cutting_sale_price,
        cutting_sale_action: this.cutting_sale_action,
        option_change_type: this.option_change_type,
        apply_option_seq: this.apply_option_seq,
        inputoption_data: this.inputoption_data,
        multi_discount_policylist: this.multi_discount_policylist,
        multi_discount_ea: this.multi_discount_ea,
        multi_discount: this.multi_discount,
        multi_discount_unit: this.multi_discount_unit,
        mobile_sale_unit: this.mobile_sale_unit,
      };
      //set_event_sale
      if (this.orderData.goods.event_sale_unit) {
        if (
          this.orderData.goods.sales.event_event_sale &&
          this.orderDatagoods.sales.event_target_sale
        ) {
          data.event_sale_unit = this.orderData.goods.sales.event_event_sale;
        } else {
          data.event_sale_unit = this.orderData.goods.sales.event_event_sale;
          data.event_target_sale = this.orderData.goods.sales.event_target_sale;
        }
      }
      //set_member_sale
      if (this.orderData.member_sale_unit) {
        if (
          this.orderData.goods.sales.member_sale_price &&
          this.orderData.goods.sales.member_sale_type
        ) {
          data.member_sale_unit = this.orderData.goods.sales.member_sale_price;
        } else {
          data.member_sale_unit = this.orderData.goods.sales.member_sale_price;
          data.member_sale_type = this.orderData.goods.sales.member_sale_type;
        }
      }
      //set_multi_sale
      if (this.orderData.goods.multi_discount_policy) {
        let policyList = [];
        if (this.orderData.goods.multi_discount_policy.policyList) {
          this.orderData.goods.multi_discount_policy.policyList.forEach(
            (li) => {
              policyList.push(
                `${li.discountOverQty}/${li.discountUnderQty}/${li.discountAmount}`
              );
            }
          );
        }
        if (policyList.length > 0) {
          data.multi_discount_policylist = policyList;
        }
        if (this.orderData.goods.multi_discount_policy.discountMaxOverQty) {
          data.multi_discount_ea =
            this.orderData.goods.multi_discount_policy.discountMaxOverQty;
        }
        if (this.orderData.goods.multi_discount_policy.discountMaxAmount) {
          data.multi_discount =
            this.orderData.goods.multi_discount_policy.discountMaxAmount;
        }
        if (this.orderData.goods.multi_discount_policy.discountUnit) {
          data.multi_discount_unit =
            this.orderData.goods.multi_discount_policy.discountUnit;
        }
      }
      //set_cutting_sale_price
      if (this.orderData.config_system.cutting_sale_price) {
        data.cutting_sale_price =
          this.orderData.config_system.cutting_sale_price;
      }
      if (this.orderData.config_system.cutting_sale_action) {
        data.cutting_sale_action =
          this.orderData.config_system.cutting_sale_action;
      }
      //set_option_change_type
      if (this.orderData.cart_options) {
        data.option_change_type = true;
        if (this.orderData.options.length > 0) {
          data.apply_option_seq = parseInt(this.orderData.cart_options.length);
        }
      }
      //set_inputoption_data
      if (this.orderData.inputs) {
        data.inputoption_data = this.orderData.inputs;
      }
      return data;
    },
  },
  methods: {
    won(str) {
      return this.$won(str);
    },
    removeItem(event) {
      const el = event.target
        .closest("div")
        .nextElementSibling.querySelector("input");
      const className = el.classList[0];
      const idx = parseInt(el.dataset.idx);
      if (className === "optionPrice") {
        if (this.isShop) {
          this.$store.dispatch("shop/updateOptions", {
            type: "remove",
            idx,
          });
        } else {
          this.$store.dispatch("order/updateOptions", {
            type: "remove",
            idx,
          });
        }
      } else {
        const parentIdx = parseInt(el.dataset.parent_idx);
        const count =
          this.orderData.cart_options[parentIdx].cart_suboptions.length;

        //카트에 담겨 있는것 부터 확인
        let title =
          this.orderData.cart_options[parentIdx].cart_suboptions[idx]
            .suboption_title;
        let isRequried = [];
        for (let sub of this.orderData.suboptions) {
          isRequried = sub.filter((s) => s.suboption_title === title);
          if (isRequried.length > 0) {
            break;
          }
        }
        if (this.isShop) {
          this.$store.dispatch("shop/updateSubOptions", {
            type: "remove",
            idx,
            parentIdx,
          });
        } else {
          if (count <= 1 && isRequried[0].sub_required.toLowerCase() === "y") {
            this.$toast.default("이 상품은 추가 상품만 삭제 할 수 없습니다.");
            return false;
          }
          this.$store.dispatch("order/updateSubOptions", {
            type: "remove",
            idx,
            parentIdx,
          });
        }
      }
      this.reRender();
    },
    eaPlus(e) {
      const inEl = e.target.closest("div").querySelector("input");
      const className = inEl.classList[0];
      inEl.value = parseInt(inEl.value) + 1;
      if (className === "optionPrice") {
        if (this.isShop) {
          this.$store.dispatch("shop/setOptionCnt", {
            type: "main",
            idx: parseInt(inEl.dataset.idx),
            cnt: parseInt(inEl.value),
          });
        } else {
          this.$store.dispatch("order/setOptionCnt", {
            type: "main",
            idx: parseInt(inEl.dataset.idx),
            cnt: parseInt(inEl.value),
          });
        }
      } else {
        if (this.isShop) {
          this.$store.dispatch("shop/setOptionCnt", {
            type: "sub",
            idx: parseInt(inEl.dataset.idx),
            parentIdx: parseInt(inEl.dataset.parent_idx),
            cnt: parseInt(inEl.value),
          });
        } else {
          this.$store.dispatch("order/setOptionCnt", {
            type: "sub",
            idx: parseInt(inEl.dataset.idx),
            parentIdx: parseInt(inEl.dataset.parent_idx),
            cnt: parseInt(inEl.value),
          });
        }
      }
      this.calculate_goods_price();
    },
    eaMinus(e) {
      const inEl = e.target.closest("div").querySelector("input");
      const className = inEl.classList[0];
      if (parseInt(inEl.value) > 1) {
        inEl.value = parseInt(inEl.value) - 1;
        if (className === "optionPrice") {
          if (this.isShop) {
            this.$store.dispatch("shop/setOptionCnt", {
              type: "main",
              idx: parseInt(inEl.dataset.idx),
              cnt: parseInt(inEl.value),
            });
          } else {
            this.$store.dispatch("order/setOptionCnt", {
              type: "main",
              idx: parseInt(inEl.dataset.idx),
              cnt: parseInt(inEl.value),
            });
          }
        } else {
          if (this.isShop) {
            this.$store.dispatch("shop/setOptionCnt", {
              type: "sub",
              idx: parseInt(inEl.dataset.idx),
              parentIdx: parseInt(inEl.dataset.parent_idx),
              cnt: parseInt(inEl.value),
            });
          } else {
            this.$store.dispatch("order/setOptionCnt", {
              type: "sub",
              idx: parseInt(inEl.dataset.idx),
              parentIdx: parseInt(inEl.dataset.parent_idx),
              cnt: parseInt(inEl.value),
            });
          }
        }
        this.calculate_goods_price();
      }
    },
    calculate_goods_price() {
      let ea = 0;
      let consumer_price = 0;
      let tot = 0;
      let price = 0;
      let tot_ea = 0;
      let goods_price = this.setData.goods_price;
      let fixed_price = 0;

      const ea_change = document.querySelectorAll(".ea_change");
      //옵션 전체 수량
      ea_change.forEach((el) => {
        tot_ea += parseInt(el.value);
        let className = el.classList[0];

        if (className === "optionPrice") {
          ea = el.value;
          consumer_price = parseInt(el.dataset.consumerPrice);
          price = parseInt(el.dataset.price);
          fixed_price = price;
          // console.log(price);

          //이벤트 할인
          if (this.setData.event_target_sale) {
            price = this.calculate_event(price, consumer_price);
            // console.log(price);
          } else {
            price = price - parseInt(this.setData.event_sale_unit);
            // console.log(price);
          }

          // 모바일할인 (모바일은 할인정보가 pc여도 나와서 mobile_sale_unit 값으로 직접 할인처리)
          price = price - parseInt(this.setData.mobile_sale_unit);
          // console.log(price);

          // 복수구매할인
          price = this.calculate_muti_discount(tot_ea, price, fixed_price);
          // console.log(price);

          // 등급할인
          if (this.setData.member_sale_type != "") {
            price = this.calculate_member(price, fixed_price);
          } else {
            this.setData.member_sale_unit = isNaN(
              parseInt(this.setData.member_sale_unit)
            )
              ? 0
              : parseInt(this.setData.member_sale_unit);
            price = price - parseInt(this.setData.member_sale_unit);
          }

          if (
            this.setData.currency == "KRW" ||
            this.setData.currency == "JPY"
          ) {
            price = parseInt(price);
          } else {
            //기본통화가 원화/엔화가 아닐 경우 소수 둘째자리까지 표기
            price = Math.floor(parseFloat(price) * 100) / 100;
          }
          if (price < 0) price = 0;
          el.nextElementSibling.nextElementSibling.innerHTML = `${this.won(
            price * ea
          )}원`;
          tot += price * ea;
        } else if (className === "suboptionPrice") {
          ea = el.value;
          consumer_price = parseInt(el.dataset.consumerPrice);
          price = parseInt(el.dataset.price);
          if (price < 0) price = 0;
          el.nextElementSibling.nextElementSibling.innerHTML = `${this.won(
            price * ea
          )}원`;
          tot += price * ea;
        }
      });
      this.$emit("setTotal", tot);
    },
    calculate_event(price, consumer_price) {
      let event_target_sale = this.setData.event_target_sale;
      let event_sale_unit = this.setData.event_sale_unit;
      let sale_price = 0;

      switch (event_target_sale) {
        case "0":
          // 할인가
          sale_price = (price * event_sale_unit) / 100;
          break;
        case "1":
          // 정가
          sale_price = (consumer_price * event_sale_unit) / 100;
          break;
        case "2":
          // 금액
          sale_price = event_sale_unit;
          break;
      }
      price = price - this.sale_price_cutting(sale_price);
      return price;
    },
    calculate_muti_discount(ea, price, fixed_price) {
      if (
        !this.setData.multi_discount_policylist &&
        !this.setData.multi_discount
      )
        return price;
      let multi_min_ea = 0;

      // 슬라이딩일 경우
      this.setData.multi_discount_policylist.forEach((policy) => {
        let policyArr = policy.split("/");
        let min_ea = policyArr[0];
        let max_ea = policyArr[1];
        let disc = policyArr[2];
        let multi_min_ea = max_ea;

        if (min_ea && max_ea && disc) {
          if (min_ea <= ea && ea < max_ea) {
            if (this.setData.multi_discount_unit == "PER" && disc <= 100) {
              price -= this.sale_price_cutting(
                Math.floor((fixed_price * disc) / 100)
              );
            } else if (price > parseFloat(disc)) {
              price -= this.sale_price_cutting(disc);
            }
          }
        } else if (
          min_ea &&
          disc &&
          this.setData.multi_discount_policylist.length == 1 &&
          ea >= min_ea
        ) {
          if (this.setData.multi_discount_unit == "PER" && disc <= 100) {
            price -= this.sale_price_cutting(
              Math.floor((fixed_price * disc) / 100)
            );
          } else if (price > parseFloat(disc)) {
            price -= this.sale_price_cutting(disc);
          }
        }
      });

      // 마지막 슬라이딩일 경우
      if (
        this.setData.multi_discount &&
        this.setData.multi_discount_unit &&
        multi_min_ea <= ea
      ) {
        if (
          this.setData.multi_discount_unit == "PER" &&
          this.setData.multi_discount <= 100
        ) {
          price -= this.sale_price_cutting(
            Math.floor((fixed_price * this.setData.multi_discount) / 100)
          );
        } else if (price > parseFloat(this.setData.multi_discount)) {
          price -= this.sale_price_cutting(this.setData.multi_discount);
        }
      }
      return price;
    },
    sale_price_cutting(price) {
      if (this.setData.cutting_sale_price > 0) {
        if (this.setData.cutting_sale_action == "dscending") {
          price =
            Math.floor(price / this.setData.cutting_sale_price) *
            this.setData.cutting_sale_price;
        }
        if (this.cutting_sale_action == "rounding") {
          price =
            Math.round(price / this.setData.cutting_sale_price) *
            this.setData.cutting_sale_price;
        }
        if (this.cutting_sale_action == "ascending") {
          price =
            Math.ceil(price / this.setData.cutting_sale_price) *
            this.setData.cutting_sale_price;
        }
      }
      return price;
    },
    calculate_member(price, fixed_price) {
      let member_sale_unit = this.setData.member_sale_unit;
      let member_sale_type = this.setData.member_sale_type;
      if (member_sale_type == "PER") {
        let sale_price = (fixed_price * parseFloat(member_sale_unit)) / 100;
        sale_price = this.sale_price_cutting(sale_price);
        price = price - sale_price;
      } else {
        price = price - member_sale_unit;
      }
      return price;
    },
    //강제로 리 렌더링 시키기
    reRender() {
      this.$forceUpdate();
    },
  },
};
</script>

<style></style>
