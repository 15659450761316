<template>
  <div class="rn_my_wrap" v-if="orderData">
    <div class="rn_my_tit_2">
      주문 수정<router-link to="/mypage/my_cart/modify"
        ><img src="@/assets/images/sub/back_ico.png" alt=""
      /></router-link>
    </div>
    <!---->
    <div class="rn_my_empty_30"></div>
    <!---->
    <div class="rn_my_box_9">
      <div class="top">
        <ul>
          <li>주문 전 장바구니 상품은 7일간 임시 보관합니다.</li>
          <li>
            장바구니에 보관된 상품의 가격, 옵션 등 상품정보가 변경된 경우 주문이
            불가능합니다.
          </li>
        </ul>
      </div>
      <div class="bottom">
        <h5>주문내용 수정</h5>
        <div class="box">
          <h6>{{ orderData.goods.provider_name }}</h6>
          <div class="info_box">
            <div class="sect_info_1">
              <div class="img">
                <img :src="orderData.goods.image" @error="replaceImg" alt="" />
              </div>
              <div class="txt">
                <h3>{{ orderData.goods.goods_name }}</h3>
                <!-- <h4>
                  {{ won(parseInt(orderData.goods.sale_price)) }}원
                  <span
                    v-if="
                      parseInt(orderData.goods.sale_price) <
                      parseInt(orderData.goods.price)
                    "
                    >{{ won(parseInt(orderData.goods.price)) }}</span
                  >
                </h4> -->
                <p v-if="parseInt(orderData.goods.provider_seq) > 1">
                  {{ orderData.goods.provider_name }}&nbsp;&nbsp;|&nbsp;&nbsp;램
                  가게
                </p>
                <p v-else>본사</p>
              </div>
            </div>
            <div
              class="sect_info_2"
              v-for="(order, index) in orderData.option_data"
              :key="index"
            >
              <div class="tit">상품 옵션 ({{ order.title }})</div>
              <select
                name="default_option"
                @change="addOption($event)"
                @focus="tempName($event)"
                :data-idx="index"
                :data-title="order.title"
              >
                <option value="">선택</option>
                <option
                  v-for="(option, idx) in order.options"
                  :key="`opt_${idx}`"
                  :value="option.opt"
                  :v-model="defaultOption"
                  v-html="option.opt_string"
                >
                  {{
                    option.chk_stock
                      ? option.opt_string
                      : `${option.opt_string}[품절]`
                  }}
                </option>
              </select>
            </div>

            <div
              class="sect_info_2"
              v-for="(sub, sidx) in orderData.suboptions"
              :key="`sub_${sidx}`"
            >
              <div class="tit">추가 상품 ({{ sub[0].suboption_title }})</div>
              <select
                name="subOption"
                @change="addOption($event)"
                @focus="tempName($event)"
                :data-title="sub[0].suboption_title"
                :data-required="sub[0].sub_required.toLowerCase()"
              >
                <option value="">
                  {{
                    sub[0].sub_required.toLowerCase() === "n"
                      ? "선택"
                      : "필수선택"
                  }}
                </option>
                <option
                  v-for="(sop, oidx) in sub"
                  :value="sop.suboption"
                  :key="`sub_op_${oidx}`"
                >
                  {{ sop.suboption
                  }}{{
                    sop.price
                      ? `&nbsp;(추가 ${won(parseInt(sop.price))}&#x20a9;)`
                      : ""
                  }}
                  {{ !sop.chk_stock ? "[품절]" : "" }}
                </option>
              </select>
              <!-- <router-link
                to=""
                @click.native="optionPlus($event)"
                v-if="sub[0].sub_required.toLowerCase() === 'y'"
                >+</router-link
              > -->
              <!-- <div class="rn_my_box_btn black">
                <div>
                  <a href="#">옵션 선택</a>
                </div>
              </div> -->
            </div>
            <!-- 상품 옵션 및 상품 시작 -->
            <OptionItem
              :orderData="orderData"
              @setTotal="setTotal"
              ref="optionItem"
            />
            <!-- // -->
            <ul class="sect_info_4">
              <!-- <li>상품금액 : {{ totalCost }}원</li>
              <li>배송비 : 무료</li> -->
              <li class="common">
                상품 금액 : <b>총 {{ totalCost }}원</b>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!---->
    <div class="rn_my_empty_15"></div>
    <!---->
    <div class="rn_my_box_btn_2">
      <router-link to="/mypage/my_cart/modify" class="gray">취소</router-link
      ><router-link to="" class="yellow" @click.native="setOptionChange"
        >확인</router-link
      >
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import OptionItem from "@/components/mypage/mycart/OptionItem";
export default {
  data() {
    return {
      tmpName: "",
      addData: {},
      tmpOptData: "",
      totalCost: 0,
      defaultOption: "",
      no: this.$route.params.no,
    };
  },
  created() {
    //초기화;
    this.$store.dispatch("common/setFooter", {
      mypage: true,
    });
    this.getOptionalChanges();
  },
  computed: {
    ...mapState("order", ["orderData", "result", "msg"]),
    isRequried() {
      if (this.orderData.suboptions) {
        for (let value of this.orderData.suboptions) {
          if (value[0].sub_required.toLowerCase() === "y") {
            return true;
          }
        }
      }
      return false;
    },
  },
  methods: {
    replaceImg(e) {
      e.target.src = require("@/assets/images/noimage.gif");
    },
    won(str) {
      return this.$won(str);
    },
    tempName(event) {
      this.tmpName = event.target.value;
    },
    async addOption(event) {
      let defaultOption = "";
      let subOption = "";
      if (event.target.name === "default_option") {
        //필수 옵션 들이 다 들어가 있는지 확인
        if (this.isRequried) {
          if (!this.requriedItemCheck(event)) {
            return false;
          }
        }
        if (this.orderData.goods.option_view_type.toLowerCase() !== "join") {
          this.moreOption(event.target.dataset.idx);
          return false;
        }
        defaultOption = event.target.value;

        //메인 옵션이 바뀌면 추가 옵션 초기화
        [...document.querySelectorAll("select[name='subOption'")].map(
          (sel) => (sel.value = "")
        );

        //매인 옵션 일 경우
        if (!defaultOption) {
          //매인 옵션 인 데 선댁 값이 없으면 초기화
          this.addData = {};
          return false;
        }

        //임시 배열
        let data = [];
        let titleArray = [];
        this.orderData.option_data.map((option) => {
          // 맵 돌면서 필터로 같은 옵션 찾아서 임시 배열에 추가
          (data = option.options.filter((op) => op.opt === defaultOption)),
            (titleArray = option.title.split(","));
        });

        // 본 옵션 데이터 추가
        this.addData.title1 = titleArray[0] || "";
        this.addData.title2 = titleArray[1] || "";
        this.addData.title3 = titleArray[2] || "";
        this.addData.title4 = titleArray[3] || "";
        this.addData.title5 = titleArray[4] || "";
        this.addData.option1 = data[0].option1;
        this.addData.option2 = data[0].option2;
        this.addData.option3 = data[0].option3;
        this.addData.option4 = data[0].option4;
        this.addData.option5 = data[0].option5;
        this.addData.ea = "1";
        this.addData.consumer_price = data[0].consumer_price;
        this.addData.price = parseInt(data[0].price);
        this.addData.cart_suboptions = [];
        this.addData.opt = data[0].opt;

        // 추가 옵션이 필수 라면 추가 상품을 선택 해주세요
        if (this.isRequried) {
          // 동일 옵션 존재 여부 확인
          try {
            await this.dupleCheck();
            this.$toast.default("필수 추가 상품을 선택 해주세요.");
          } catch (e) {
            this.$toast.default("동일한 옵션이 존재 합니다.");
            this.tmpOptData = this.addData.opt;

            //선택 옵션 데이터 초기화
            this.addData = {};
          }
        } else {
          // 추가 상품이 필수 가 아니면 orderData  에 추가
          try {
            await this.dupleCheck();
            this.$store.dispatch("order/updateOptions", {
              type: "add",
              data: this.addData,
            });
          } catch (e) {
            this.$toast.default("동일한 옵션이 존재 합니다.");
          }
          this.tmpOptData = this.addData.opt;

          //선택 옵션 데이터 초기화
          this.addData = {};
        }
      } else {
        //추가 옵션 일 겨우
        subOption = event.target.value;

        if (!subOption) {
          return false;
        }
        // 동일 옵션 존재 여부 확인
        try {
          await this.dupleCheck();
        } catch (e) {
          this.$toast.default("동일한 옵션이 존재 합니다.");
          return false;
        }

        //본 온션 선택 데이터가 있는 경우
        if (!this.addData.cart_suboptions) {
          //orderData 에 카트 옵션 데이터가 있다면
          if (this.orderData.cart_options.length > 0) {
            //임시 배열
            let data = [];
            // 맵 돌면서 필터로 같은 옵션 찾아서 임시 배열에 추가
            this.orderData.suboptions.map((sub) => {
              data = [...data, ...sub.filter((s) => s.suboption === subOption)];
            });

            //추가 옵션 검사
            let isRequriedOk = false;
            if (this.isRequried) {
              //필수 옵션 이 있는 상품 이라면
              for (let v of data) {
                //찾아온 데이터에서 검사
                if (v.sub_required.toLowerCase() !== "y") {
                  //선택한 추가 옵션이 필수 가 아니라면
                  for (let sub of this.orderData.suboptions) {
                    //추가 옵션을 돌면서...
                    for (let subSub of sub) {
                      if (subSub.sub_required.toLowerCase() === "y") {
                        //필수 추가 옵션 항목이 맞다면
                        for (let subCart of this.orderData.cart_options[
                          this.orderData.cart_options.length - 1
                        ].cart_suboptions) {
                          //카트에 마지막 배열을 돌면서 카트에 필수 추가 상품이 있는지 검사
                          if (
                            subCart.suboption_title ===
                              subSub.suboption_title &&
                            subCart.suboption === subSub.suboption
                          ) {
                            isRequriedOk = true;
                            break;
                          }
                        }
                      }
                      if (isRequriedOk) {
                        break;
                      }
                    }
                  }
                  if (!isRequriedOk) {
                    //선택한 추가 옵션과 카트에 옵션 중에 필수 추가 옵션이 없으면 ...
                    this.$toast.default("필수 추가 상품을 선택 해주세요.");
                    event.target.value = "";
                    return false;
                  }
                }
              }
            }

            //동일한 옵션에  동일한 추가 상품 검사
            let tmp;
            let check = true;
            let index = 0;
            this.orderData.cart_options.forEach((item, idx) => {
              if (item.option1) {
                tmp = item.option1;
              }
              if (item.option2) {
                tmp = `${item.option1}/${item.option2}`;
              }
              if (item.option3) {
                tmp = `${item.option1}/${item.option2}/${item.option3}`;
              }
              if (item.option4) {
                tmp = `${item.option1}/${item.option2}/${item.option3}/${item.option4}`;
              }
              if (item.option5) {
                tmp = `${item.option1}/${item.option2}/${item.option3}/${item.option4}/${item.option5}`;
              }

              if (tmp === this.tmpOptData) {
                index = idx;
                this.orderData.cart_options[idx].cart_suboptions.forEach(
                  (sub) => {
                    data.forEach((dt) => {
                      if (
                        dt.suboption_title === sub.suboption_title &&
                        dt.suboption === sub.suboption
                      ) {
                        check = false;
                      }
                    });
                  }
                );
              }
            });
            if (!check) {
              this.$toast.default(
                "동일한 옵션에 동일한 추가 상품이 존재 합니다."
              );
              return false;
            }
            //orderData 에 상품 데이터 추가
            data.map((dt) => {
              this.$store.dispatch("order/updateSubOptions", {
                idx: index,
                type: "add",
                data: {
                  suboption_title: dt.suboption_title,
                  suboption: dt.suboption,
                  ea: "1",
                  price: dt.price,
                  consumer_price: dt.consumer_price,
                },
              });
            });

            this.$refs.optionItem.reRender();
            //선택 옵션 데이터 초기화
            this.addData = {};
          } else {
            //orderData에 카트 상품이 없다면 본 상품을 선택 해주세요
            this.$toast.default("상품 옵션을 선택 해주세요.");
            subOption = "";
            return false;
          }
        } else {
          //선택한 메인 옵션이 있을경우

          //임시 배열
          let data = [];

          // 맵 돌면서 필터로 같은 추가 옵션 찾아서 임시 배열에 추가
          this.orderData.suboptions.map((sub) => {
            data = [...data, ...sub.filter((s) => s.suboption === subOption)];
          });

          if (this.isRequried) {
            for (let v of data) {
              if (v.sub_required.toLowerCase() !== "y") {
                this.$toast.default("필수 추가 상품을 선택 해주세요.");
                event.target.value = "";
                return false;
              }
            }
          }
          //상품 배열에 추가 상품 추가
          data.map((dt) => {
            this.addData.cart_suboptions.push({
              suboption_title: dt.suboption_title,
              suboption: dt.suboption,
              ea: "1",
              price: dt.price,
              consumer_price: dt.consumer_price,
            });
          });

          //orderData에 상품 데이터 추가
          this.$store.dispatch("order/updateOptions", {
            type: "add",
            data: this.addData,
          });

          this.tmpOptData = this.addData.opt;
          //선택 옵션 데이터 초기화
          this.addData = {};
        }
      }
    },
    async moreOption(index) {
      //메인 옵션이 바뀌면 추가 옵션 초기화
      [...document.querySelectorAll("select[name='subOption'")].map(
        (sel) => (sel.value = "")
      );

      let query = `no=${this.orderData.goods.goods_seq}&member_seq=${this.orderData.sessionMember.member_seq}&max=${this.orderData.option_depth}`;
      let idx = parseInt(index) + 1;
      let array = [
        ...document.querySelectorAll("select[name='default_option'"),
      ];
      for (let i = 0; i < idx; i++) {
        query += `&options[]=${array[i].value}`;
      }
      if (idx < parseInt(this.orderData.option_depth)) {
        document.querySelectorAll("select[name='default_option'")[idx].value =
          "";
        await this.$store.dispatch("shop/moreOption", {
          query,
          idx,
          max: parseInt(this.orderData.option_depth),
          type: "order",
        });
      }
      if (idx === parseInt(this.orderData.option_depth)) {
        let seletedIdx = document.querySelectorAll(
          "select[name='default_option'"
        )[idx - 1].selectedIndex;
        let data = this.orderData.option_data[idx - 1].options[seletedIdx - 1];

        // 본 옵션 데이터 추가
        this.addData.title1 = document.querySelectorAll(
          "select[name='default_option'"
        )[0]
          ? document.querySelectorAll("select[name='default_option'")[0].dataset
              .title
          : "";
        this.addData.title2 = document.querySelectorAll(
          "select[name='default_option'"
        )[1]
          ? document.querySelectorAll("select[name='default_option'")[1].dataset
              .title
          : "";
        this.addData.title3 = document.querySelectorAll(
          "select[name='default_option'"
        )[2]
          ? document.querySelectorAll("select[name='default_option'")[2].dataset
              .title
          : "";
        this.addData.title4 = document.querySelectorAll(
          "select[name='default_option'"
        )[3]
          ? document.querySelectorAll("select[name='default_option'")[3].dataset
              .title
          : "";
        this.addData.title5 = document.querySelectorAll(
          "select[name='default_option'"
        )[4]
          ? document.querySelectorAll("select[name='default_option'")[4].dataset
              .title
          : "";
        this.addData.option1 = document.querySelectorAll(
          "select[name='default_option'"
        )[0]
          ? document.querySelectorAll("select[name='default_option'")[0].value
          : "";
        this.addData.option2 = document.querySelectorAll(
          "select[name='default_option'"
        )[1]
          ? document.querySelectorAll("select[name='default_option'")[1].value
          : "";
        this.addData.option3 = document.querySelectorAll(
          "select[name='default_option'"
        )[2]
          ? document.querySelectorAll("select[name='default_option'")[2].value
          : "";
        this.addData.option4 = document.querySelectorAll(
          "select[name='default_option'"
        )[3]
          ? document.querySelectorAll("select[name='default_option'")[3].value
          : "";
        this.addData.option5 = document.querySelectorAll(
          "select[name='default_option'"
        )[4]
          ? document.querySelectorAll("select[name='default_option'")[4].value
          : "";
        this.addData.ea = "1";
        this.addData.consumer_price = data.consumer_price;
        this.addData.price = parseInt(data.price);
        this.addData.cart_suboptions = [];
        if (this.addData.option1) {
          this.addData.opt = this.addData.option1;
        }
        if (this.addData.option2) {
          this.addData.opt = `${this.addData.option1}/${this.addData.option2}`;
        }
        if (this.addData.option3) {
          this.addData.opt = `${this.addData.option1}/${this.addData.option2}/${this.addData.option3}`;
        }
        if (this.addData.option4) {
          this.addData.opt = `${this.addData.option1}/${this.addData.option2}/${this.addData.option3}/${this.addData.option4}`;
        }
        if (this.addData.option5) {
          this.addData.opt = `${this.addData.option1}/${this.addData.option2}/${this.addData.option3}/${this.addData.option4}/${this.addData.option5}`;
        }

        // 추가 옵션이 필수 라면 추가 상품을 선택 해주세요
        if (this.isRequried) {
          // 동일 옵션 존재 여부 확인
          try {
            await this.dupleCheck();
            this.$toast.default("필수 추가 상품을 선택 해주세요.");
          } catch (e) {
            this.$toast.default("동일한 옵션이 존재 합니다.");

            this.tmpOptData = this.addData.opt;
            //선택 옵션 데이터 초기화
            this.addData = {};
          }
        } else {
          // 추가 상품이 필수 가 아니면 orderData  에 추가
          try {
            await this.dupleCheck();
            this.$store.dispatch("order/updateOptions", {
              type: "add",
              data: this.addData,
            });
          } catch (e) {
            this.$toast.default("동일한 옵션이 존재 합니다.");
          }
          this.tmpOptData = this.addData.opt;

          //선택 옵션 데이터 초기화
          this.addData = {};
        }
      }
      this.$forceUpdate();
    },
    requriedItemCheck(event = null) {
      const subSel = [...document.querySelectorAll("select[name='subOption'")];
      let check = [];
      let isTrue = true;
      for (let sub of subSel) {
        if (sub.dataset.required.toLowerCase() === "y") {
          check = this.orderData.cart_options[
            this.orderData.cart_options.length - 1
          ].cart_suboptions.filter(
            (csub) => csub.suboption_title === sub.dataset.title
          );
          if (check.length <= 0) {
            this.$toast.default(
              `'${sub.dataset.title}' 필수 추가 상품을 선택 해주세요`
            );
            if (event) {
              event.target.value = this.tmpName;
            }
            isTrue = false;
            break;
          }
        }
      }
      return isTrue;
    },
    dupleCheck() {
      return new Promise((resolve, reject) => {
        let option = "";
        this.orderData.cart_options.forEach((op) => {
          option = op.title1 && `${op.option1}`;
          if (option === this.addData.opt) reject();

          option = op.title2 && `${op.option1}/${op.option2}`;
          if (option === this.addData.opt) reject();

          option = op.title3 && `${op.option1}/${op.option2}/${op.option3}`;
          if (option === this.addData.opt) reject();

          option =
            op.title4 &&
            `${op.option1}/${op.option2}/${op.option3}/${op.option4}`;
          if (option === this.addData.opt) reject();

          option =
            op.title5 &&
            `${op.option1}/${op.option2}/${op.option3}/${op.option4}/${op.option5}`;
          if (option === this.addData.opt) reject();
        });
        resolve();
      });
    },
    setTotal(str) {
      this.totalCost = this.won(parseInt(str));
    },
    async getOptionalChanges() {
      await this.$store.dispatch("order/getOptionalChanges", {
        no: this.no,
      });
    },
    async setOptionChange() {
      if (!this.requriedItemCheck()) {
        return false;
      }
      let formData = new FormData();
      formData.append(
        "cart_option_seq",
        this.orderData.cart_options[0].cart_option_seq
      );
      formData.append("hop_select_date", this.orderData.cart.shipping_hop_date);
      formData.append(
        "shipping_prepay_info",
        this.orderData.cart.shipping_prepay_info
      );
      formData.append(
        "shipping_store_seq",
        this.orderData.cart.shipping_store_seq
      );
      formData.append(
        "option_select_goods_seq",
        this.orderData.goods.goods_seq
      );
      formData.append(
        "option_select_provider_seq",
        this.orderData.goods.provider_seq
      );
      formData.append("gl_option_select_ver", "0.1");
      formData.append(
        "option_select_goods_seq",
        this.orderData.goods.goods_seq
      );
      formData.append("use_add_action_button", "y");
      formData.append("viewOptions[]", "");
      formData.append("viewSuboption[]", "");

      this.orderData.cart_options.forEach((cart, idx) => {
        if (cart.cart_option_seq) {
          formData.append(`exist_option_seq[${idx}]`, cart.cart_option_seq);
        }
      });
      if (this.isRequried) {
        this.orderData.suboptions.map((sub) => {
          if (sub[0].sub_required.toLowerCase() === "y") {
            formData.append(
              "suboption_title_required[]",
              sub[0].suboption_title
            );
          }
        });
      }
      const inputELMain = document.querySelectorAll(".optionPrice");
      const inputELSub = document.querySelectorAll(".suboptionPrice");
      let titleArray = [];
      let mainOpt = [];
      let isNull = true;
      inputELMain.forEach((el, i) => {
        titleArray = el.dataset.title.split("/");
        mainOpt = el.dataset.option.split("/");
        if (titleArray[0] !== "null") {
          isNull = false;
          titleArray.forEach((title, j) => {
            if (mainOpt[j]) {
              formData.append(`option[${i}][${j}]`, mainOpt[j]);
              formData.append(`optionTitle[${i}][${j}]`, title);
            }
          });
          formData.append(`optionEa[${i}]`, el.value);
          inputELSub.forEach((sub, k) => {
            if (parseInt(sub.dataset.parent_idx) === i) {
              formData.append(
                `suboption[${i}][${parseInt(sub.dataset.idx)}]`,
                sub.dataset.sub_option
              );
              formData.append(
                `suboptionTitle[${i}][${parseInt(sub.dataset.idx)}]`,
                sub.dataset.sub_title
              );
              formData.append(
                `suboptionEa[${i}][${parseInt(sub.dataset.idx)}]`,
                sub.value
              );
            }
          });
        }
      });

      if (isNull) {
        formData.append(`option[0][0]`, "");
        formData.append(`optionTitle[0][0]`, "");
        formData.append(`optionEa[0]`, inputELMain[0].value);
      }
      // for (let pair of formData.entries()) {
      //   console.log(`${pair[0]}: ${pair[1]}`);
      // }

      await this.$store.dispatch("order/setOptionChange", formData);
      this.$toast.default(this.msg);
      this.$router.push("/mypage/my_cart/modify");
    },
  },
  components: {
    OptionItem,
  },
};
</script>

<style lang="scss" scoped>
.rn_my_box_btn {
  padding: 0;
  margin: 10px 0;
}
</style>
